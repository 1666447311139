import axios from 'axios'
import React, { useState } from 'react'


export default function Register(props) {

        const [username, setUsername] = useState("");
        const [password, setPassword] = useState("");
        const [email, setEmail] = useState("");
        const [confirmPassword, setconfirmPassword] = useState("");
       
    const register = (e,props) =>{
        e.preventDefault();
       props.triggerLoad();
        //eslint-disable-next-line
        const illegalCharacters = `/^[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]*$/`.split("");
        for(let i=0;i<illegalCharacters.length;i++){
            if(username.includes(illegalCharacters[i])){
              
                props.illegalcharacter(illegalCharacters[i]);
                return 0;
            }
        }
        
     
        if(username===""||password===""||email===""){
             props.stopLoad();
             props.empty();
             return 0;
        }
        if(password===confirmPassword){
              axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/register",{
                data:{
                username:username,
                password:password,
                email:email
                }
        }).then((res)=>{
           if(res.data==="Success"){
                props.stopLoad();
               props.success();

           }else if(res.data==="Taken"){
          props.stopLoad();
               props.takenUser();
           }
        })
        }else{
             props.stopLoad();
           props.dontMatch();
        }
    }
  
    return (
      <>
     
        <h1 className='loginh1'>Register</h1>
        <form className="logininputs">
        <label htmlFor="username" >Username</label>
        <input type="text" name='username' autoComplete='username' value={username} onChange={(e) =>setUsername(e.target.value)}/>
        <label htmlFor="email">Email</label>
        <input type="email" name='email' autoComplete='email' value={email} onChange={(e) =>setEmail(e.target.value)}/>
        <label htmlFor="password">Password</label>
        <input type="password" name='password' autoComplete='new-password' value={password} onChange={(e) =>setPassword(e.target.value)}/>
        <label htmlFor="repeatpassword">Repeat Password</label>
        <input type="password" name='repeatpassword' autoComplete='new-password' value={confirmPassword} onChange={(e) =>setconfirmPassword(e.target.value)}/>
         <button className='loginbutton' onClick={(e) =>register(e,props)}>Register</button>
        </form>
        
         <div className="notlogged">Already have an account?  <div className="underline" onClick={props.showLogin}>Login here</div></div>
        </>
    )
}
