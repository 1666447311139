import axios from 'axios';
import React, { useRef, useState } from 'react'
export default function Comments(props) {
  const [text, setText] = useState("");


  let id=0,id_reply=0;
  const comments = useRef(null);
  const comment_list = useRef();
  const [userid, setUserid] = useState("");
  const [canComment, setComment]=useState(true);
  const replyRef = useRef("");
  const textRef = useRef("");
  let cooldown = false;
const forceUpdate = React.useReducer(bool => !bool)[1];

       axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/get_comments",{id:props.id,user:localStorage.getItem("user")}).then(res =>{
         comments.current=JSON.parse(res.data.resp[0].comments);
         if(localStorage.getItem("Login")!==null){
             setUserid(res.data.user[0].id);
         }else{
            setComment(false);
         }
        
       });


  
  const postComment = () =>{
    let length;
    comments.current===null ? length=0 : length=comments.current.length;
    if(text.length>10){
     axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/post_comment",{text,author:localStorage.getItem("user"),id:props.id,length}).then(res =>{
       
     })
    if(comments.current===null) comments.current=[];
    comments.current.push({
       author:localStorage.getItem("user"),
                text,
                likes:0,
                liked_by:[],
                dislikes:0,
                disliked_by:[],
                replies:[]
    })
    setText("");
      props.success("Comment posted!")
    }else{
      props.postFail("Comment is too short")
    }
  }



  const addLike = (item,reply,isReply) =>{
    if(!cooldown){
    let liked,disliked,dislike_count,like_count;
      if(isReply===true){
  
        liked=comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[0].id;
        disliked=comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[2].id;
         like_count =Number(comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[1].innerHTML.split("(")[1].split(")")[0]);
          dislike_count =Number(comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[3].innerHTML.split("(")[1].split(")")[0]);
    }else{
        liked = comment_list.current.children[item.id+1].children[0].children[2].children[0].id;
        disliked = comment_list.current.children[item.id+1].children[0].children[2].children[2].id;
        dislike_count =Number(comment_list.current.children[item.id+1].children[0].children[2].children[3].innerHTML.split("(")[1].split(")")[0]);
        like_count =Number(comment_list.current.children[item.id+1].children[0].children[2].children[1].innerHTML.split("(")[1].split(")")[0]);
    }


    
    
        if(liked !=="yes"){
            //If clicked like when dislike active
          if(disliked==="yes"){
               if(isReply===true){
                  comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[2].style.color="#fff";
                  comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[2].id="no";
                    dislike_count--;
              axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/reply_dislike_comment_remove",{userid:userid,id:props.id,which:item.id}).then(res =>{
                      axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/reply_like_comment",{liked_by:localStorage.getItem("user"),id:props.id,item,which:reply.id})
                        comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[3].innerHTML=`(${dislike_count})`;
                    })
            
            }
            else{ 
                 comment_list.current.children[item.id+1].children[0].children[2].children[2].id="no";
            comment_list.current.children[item.id+1].children[0].children[2].children[2].style.color="#fff";
              dislike_count--;
              axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/dislike_comment_remove",{userid:userid,id:props.id,which:item.id}).then(res =>{
                         axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/like_comment",{liked_by:localStorage.getItem("user"),id:props.id,item})
                        comment_list.current.children[item.id+1].children[0].children[2].children[3].innerHTML=`(${dislike_count})`;
                    })
            }
            //If clicked like when dislike inactive
          }else{
            if(isReply===true){
                axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/reply_like_comment",{liked_by:localStorage.getItem("user"),id:props.id,item,which:reply.id})
            }else{
                axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/like_comment",{liked_by:localStorage.getItem("user"),id:props.id,item})
            }
          }
          //Update like count
          if(isReply===true){
                 comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[0].style.color="green";
                  comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[0].id="yes";
                  like_count++;
                  comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[1].innerHTML=`(${like_count})`;
          }else{
               comment_list.current.children[item.id+1].children[0].children[2].children[0].style.color="green";
                comment_list.current.children[item.id+1].children[0].children[2].children[0].id="yes";
                  like_count++;
                  comment_list.current.children[item.id+1].children[0].children[2].children[1].innerHTML=`(${like_count})`;
          }
        }
        //If clicked when already liked
        else{
          if(isReply===true&&like_count!==0){
             comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[0].style.color="#fff";
                  comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[0].id="no";
              like_count--;
                 axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/reply_like_comment_remove",{userid:userid,id:props.id,which:item.id})
                  comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[1].innerHTML=`(${like_count})`;
          }else if(like_count!==0){
               comment_list.current.children[item.id+1].children[0].children[2].children[0].id="no";
            comment_list.current.children[item.id+1].children[0].children[2].children[0].style.color="#fff";
              like_count--;
                 axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/like_comment_remove",{userid:userid,id:props.id,which:item.id})
                  comment_list.current.children[item.id+1].children[0].children[2].children[1].innerHTML=`(${like_count})`;
          }
        

        }
      //Add cooldown, so that server can manage
        cooldown=true;
        setTimeout(() =>cooldown=false, 1000);
  }else{
    props.postFail("Please wait a second before liking or disliking again")
  }
  }
  const addDisLike = (item,reply, isReply) =>{
    if(!cooldown){
     //Check if comment already liked
    let liked,disliked;
          //  Couldn't find a cleverer way
          let like_count,dislike_count;
     if(isReply===true){
        liked=comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[0].id;
        disliked=comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[2].id;
         like_count =Number(comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[1].innerHTML.split("(")[1].split(")")[0]);
         dislike_count =Number(comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[3].innerHTML.split("(")[1].split(")")[0]);
    }else{
       liked = comment_list.current.children[item.id+1].children[0].children[2].children[0].id;
       disliked = comment_list.current.children[item.id+1].children[0].children[2].children[2].id;
    
           dislike_count =Number(comment_list.current.children[item.id+1].children[0].children[2].children[3].innerHTML.split("(")[1].split(")")[0]);
            like_count =Number(comment_list.current.children[item.id+1].children[0].children[2].children[1].innerHTML.split("(")[1].split(")")[0]);
    }
        
            
  
   
        if(disliked !=="yes"){
          if(liked==="yes"&&like_count!==0){
            if(isReply===true){
              comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[0].id="no";
              comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[0].style.color="#fff";
              axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/reply_like_comment_remove",{userid:userid,id:props.id,which:reply.id}).then(res =>{
                    if(res.status===200){
                      like_count--;
                       comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[1].innerHTML=`(${like_count})`;
                    }
                         axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/reply_dislike_comment",{disliked_by:localStorage.getItem("user"),id:props.id,item,which:reply.id})
                    })
           
                              
                     comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[2].style.color="red";
                                comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[2].id="yes";
                                  dislike_count++;
                                  comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[3].innerHTML=`(${dislike_count})`;
            }else{
              comment_list.current.children[item.id+1].children[0].children[2].children[0].id="no";
              comment_list.current.children[item.id+1].children[0].children[2].children[0].style.color="#fff";
              axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/like_comment_remove",{userid:userid,id:props.id,which:item.id}).then(res =>{
                    if(res.status===200){
                      like_count--;
                        comment_list.current.children[item.id+1].children[0].children[2].children[1].innerHTML=`(${like_count})`;
                    }
                         axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/dislike_comment",{disliked_by:localStorage.getItem("user"),id:props.id,item})
                    })
                             comment_list.current.children[item.id+1].children[0].children[2].children[2].style.color="red";
                                comment_list.current.children[item.id+1].children[0].children[2].children[2].id="yes";
                                dislike_count++;
                                comment_list.current.children[item.id+1].children[0].children[2].children[3].innerHTML=`(${dislike_count})`;
            }
             
          }else{
                 if(isReply===true){
                     axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/reply_dislike_comment",{disliked_by:localStorage.getItem("user"),id:props.id,item,which:reply.id})
                               comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[2].style.color="red";
                                comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[2].id="yes";
                                dislike_count++;
                                comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[3].innerHTML=`(${dislike_count})`;
                 }else{
                      axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/dislike_comment",{disliked_by:localStorage.getItem("user"),id:props.id,item})
                                comment_list.current.children[item.id+1].children[0].children[2].children[2].style.color="red";
                                comment_list.current.children[item.id+1].children[0].children[2].children[2].id="yes";
                                dislike_count++;
                                comment_list.current.children[item.id+1].children[0].children[2].children[3].innerHTML=`(${dislike_count})`;
                 }
            
          }

        }else{
           if(isReply===true){
              comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[2].id="no";
          comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[2].style.color="#fff";
              dislike_count--;
                 axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/reply_dislike_comment_remove",{userid:userid,id:props.id,which:reply.id})
               comment_list.current.children[item.id+1].children[2+reply.id].children[3].children[3].innerHTML=`(${dislike_count})`;
           }else{
                  comment_list.current.children[item.id+1].children[0].children[2].children[2].id="no";
            comment_list.current.children[item.id+1].children[0].children[2].children[2].style.color="#fff";
              dislike_count--;
                 axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/dislike_comment_remove",{userid:userid,id:props.id,which:item.id})
                    comment_list.current.children[item.id+1].children[0].children[2].children[3].innerHTML=`(${dislike_count})`;
           }
      
        }
         //Add cooldown, so that server can manage
        cooldown=true;
        setTimeout(() =>cooldown=false, 1000);
      }else{
        props.postFail("Please wait a second before liking or disliking again")
      }
      
  }

  const replyBox = (id) =>{
      //Get current comment box
      replyRef.current=comment_list.current.children[1+id];
      const display = replyRef.current.children[1].style.display;
      if(display==="block"){
        replyRef.current.children[1].style.display="none";
      }else{
         replyRef.current.children[1].style.display="block";
      }
  }

  const postReply = (id) =>{
         axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/add_reply",{user:localStorage.getItem("user"),id:props.id,which:id,reply:textRef.current}).then(() =>{
           comments.current[id].replies.push({
                 author:localStorage.getItem("user"),
                    text:textRef.current,
                    likes:0,
                    liked_by:[],
                    dislikes:0,
                    disliked_by:[]
           })
           forceUpdate();
             replyRef.current=comment_list.current.children[1+id];
             replyRef.current.children[1].style.display="none";
         });
  }

  const updateReplyText = (val) =>{
    textRef.current=val;
  }
  const showDelete = (item,reply,isReply) =>{
    if(item.author===localStorage.getItem("user")){
       if(isReply){
         comment_list.current.children[1].children[2+reply.id].children[1].children[1].style.display="block";
              comment_list.current.children[item.id+1].children[0].children[0].children[1].style.display="none";
      }else{
        comment_list.current.children[item.id+1].children[0].children[0].children[1].style.display="block";
      }
      
    }
  }
  const hideDelete = (item,reply,isReply) =>{
    if(item.author===localStorage.getItem("user")){
       if(isReply){
        comment_list.current.children[1].children[2+reply.id].children[1].children[1].style.display="none";
        comment_list.current.children[item.id+1].children[0].children[0].children[1].style.display="block";
      }else{
       comment_list.current.children[item.id+1].children[0].children[0].children[1].style.display="none";
      }
     
    }
  }
  const deleteComment = (item,reply,isReply) =>{
    axios.post(("https://us-central1-servers-72707.cloudfunctions.net/app/delete_comment"),{id:props.id,which:item.id,isReply,reply_id:reply.id}).then((res)=>{
      forceUpdate();
      props.success("Comment deleted.")
      
    })
  }
  return (
    <div>
            <h1 className="page_header">COMMENTS</h1>
                <div className="comments" ref={comment_list}>
                  <div className="insert_comment_box" style={{display:canComment===false && "none"}}>
                   
                        <textarea className="insert_comment"  placeholder="Insert your comment here" value={text} onChange={(e) =>setText(e.target.value)}></textarea>
                       <div className="comment_post" onClick={() =>postComment()}><p>Post comment</p> <i className="fas fa-upload"></i></div> 
                       
                    </div>
                 
                  { comments.current!==null&& comments.current.map((item) =>{
                   
                   //Understand which comment when liking, disliking or replying
                   item.id=id;
                   id++;
                    let likedstate = item.liked_by.includes(userid) ? "yes" : "no";
                    let dislikedstate = item.disliked_by.includes(userid) ? "yes" : "no";
                return <div className="comment_box_div" id={item.id} key={Math.random()} onMouseEnter={() =>showDelete(item)} onMouseLeave={() =>hideDelete(item)}>
                  <div className="comment_box"  id={item.id}>
                    <h3 className="comment_author">  <i className="fas fa-user post_avatar"></i><p className='comment_author_name'>{item.author}</p> <i className="fa fa-trash" style={{display:"none"}} onClick={() =>deleteComment(item)}></i></h3>
                        <p>{item.text}</p>
                        <div className="reactions" style={{display:canComment===false && "none"}}>
                   <i className="fa-solid fa-thumbs-up" style={{color:likedstate==="yes" ? "green" : "#fff"}} id={likedstate} onClick={(e) =>{addLike(item)}}> </i> <div className="count">({item.likes})</div> 
                      <i className="fa-solid fa-thumbs-down" onClick={() =>{addDisLike(item)}} style={{color:dislikedstate==="yes" ? "red" : "#fff"}} id={dislikedstate}>   </i><p className="count">({item.dislikes})</p>
                        <i className="fa-solid fa-reply" onClick={() =>replyBox(item.id)}></i>
                        </div>
                    </div>
                      <div className="writereply" id={item.id} style={{display:"none"}}><div className="insert_comment_box">
                        <textarea className="insert_comment"  placeholder="Type your reply here"  onChange={(e) =>{updateReplyText(e.target.value)}}></textarea>
                       <div className="comment_post" onClick={() =>postReply(item.id)}><p>Reply</p> <i className="fa-solid fa-reply"></i></div> 
                       
                    </div></div>
                      {item.replies.length!==0&&item.replies.map((reply)=>{
                        //Understand which comment when liking, disliking or replying
                        reply.id=id_reply;
                        id_reply++;
                    let r_likedstate = reply.liked_by.includes(userid) ? "yes" : "no";
                    let r_dislikedstate = reply.disliked_by.includes(userid) ? "yes" : "no";
                        return(
                            <div className="reply_comment_box" key={Math.random()} onMouseEnter={() =>showDelete(item,reply,true)} onMouseLeave={() =>hideDelete(item,reply,true)}>
                    <div className="comment_reply_line"></div>
                    <h3 className="comment_author">  <i className="fas fa-user post_avatar"></i>{reply.author} <i className="fa fa-trash" style={{display:"none"}} onClick={() =>deleteComment(item,reply,true)}></i></h3>
                        <p>{reply.text}</p>
                        <div className="reactions" style={{display:canComment===false && "none"}}>
                   <i className="fa-solid fa-thumbs-up" style={{color:r_likedstate==="yes" ? "green" : "#fff"}} id={r_likedstate} onClick={(e) =>{addLike(item,reply,true)}}> </i> <div className="count">({reply.likes})</div> 
                      <i className="fa-solid fa-thumbs-down" onClick={() =>{addDisLike(item,reply,true)}} style={{color:r_dislikedstate==="yes" ? "red" : "#fff"}} id={r_dislikedstate}>   </i><p className="count">({reply.dislikes})</p>
                        </div>
                    </div>
                        )
                      })}
                  
                 
                    </div>
                 
              })}
                   
                </div>
            
               
    </div>
  )
}
