
import './App.scss';
import {BrowserRouter as Router,Route,Routes} from "react-router-dom";

import ManagePosts from './components/ManagePosts';
import Navbar from './components/Navbar';
import React, { useRef } from 'react';

import { useState } from 'react';
import './App.scss';
import Blogposts from './components/Blogposts';

import Login from './components/Login';

//import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


import Register from './components/Register';
import Alertbox from './components/Alertbox';
import axios from 'axios';
import NewPost from './components/NewPost';
import Detailpost from './components/Detailpost';
import Introduction from './components/Introduction';
import Footer from './components/Footer';
import Description from './components/Description';
import Settings from './components/Settings';
import ChangePassword from './components/ChangePassword';
import ChangePasswordLink from './components/ChangePasswordLink';
import Loading from './components/Loading';

function App() {
const [display,changeDisplay] = useState("none")
const [displayReg,changeDisplayReg] = useState("none")
const [alert,setAlert] = useState("");
const [successclr,setSuccessclr] = useState(false);
const [loginstate,setLoginState]=useState("");
const loading = useRef();



  //No useEffect => less rerender?
   
    if(localStorage.getItem("Login")!==null){
     axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/auth",{
       data:localStorage.getItem("Login")
     }).then((res)=>{
      if(res.data==="loggedIn"){
        setLoginState(true);
      }else{
         setLoginState(false);
      }
     
     })
    
   }


 
   
   const date = new Date();
   if(Number(localStorage.getItem("Cooldown"))+600000<date.getTime()) localStorage.removeItem("Cooldown");


  

const showLogin = () =>{
  if(displayReg==="block") {
  changeDisplayReg("none");
 // enableBodyScroll(document); Bad on mobile
}
if(display==="none") 
{
changeDisplay("block");
//disableBodyScroll(document);

}

}
const closeLogin = () =>{
if(display==="block") {
  changeDisplay("none");
  //enableBodyScroll(document);
}
}
const showReg = () =>{
  if(display==="block") {
  changeDisplay("none");
  //enableBodyScroll(document);
}
if(displayReg==="none") 
{
changeDisplayReg("block");
// disableBodyScroll(document); 

}

}
const closeReg = () =>{
if(displayReg==="block") {
  changeDisplayReg("none");
 // enableBodyScroll(document); 
}
}

  

    const postFail = (alert) =>{
       setSuccessclr(false);
      setAlert(alert);
       setTimeout(() => {setAlert("")}, 3000)
    }
  
    const greenAlert = (alert) =>{
       setSuccessclr(true);
        setAlert(alert);
       setTimeout(() => {setAlert("")}, 3000)
    }
  return (
<Router>
   <Navbar onLoginClick={() =>showLogin()} onRegClick={() =>showReg()} loggedIn={loginstate}  image={`${window.location.origin}/Postato.png`}/>
   
          <Alertbox message={alert} success={successclr}/>
          <div className="regbackground" style={{display:displayReg}} onClick={() =>closeReg()} />
          <div className="regbox" style={{display:displayReg}}>
          <Register triggerLoad= {() =>loading.current.style.display="flex"} stopLoad={() =>loading.current.style.display="none"} showLogin={() =>showLogin()} success={() =>{greenAlert("Account created successfully. Log in to continue"); closeReg();}} takenUser={() =>postFail("Username is taken")} dontMatch = {() =>postFail("Passwords don't match")} empty={() =>postFail("Please fill in all the empty fields")} illegalcharacter={(char) =>postFail(`Characters like ${char} are not allowed in the username`)}/>
           </div>
            <div className="regbackground" style={{display:display}} onClick={() =>closeLogin()} />
          <div className="loginbox" style={{display:display}}>
          <Login triggerLoad= {() =>loading.current.style.display="flex"} stopLoad={() =>loading.current.style.display="none"} fillIn={() =>postFail("Please fill in all the empty fields")} loggedIn= {()=>{greenAlert("Logged in successfully");setLoginState(true);closeLogin()}} wrong ={() =>postFail("Wrong username or password")} showReg = {() =>showReg()}/>
         </div>
  <Routes>
     <Route path="/" element={
       <>
           <div ref={loading} className="regload"><Loading/></div>
          <Introduction />
          <Blogposts  />
          
        </>
     }/> 
     <Route path="/settings" element={
       <>
      <Settings cooldown={() =>postFail("Please wait a bit before changing your password")} success={(item) =>greenAlert(item)}/> 
       </>} />
    
      <Route path="/new_post" element={
        <>
     <NewPost closePost={() =>greenAlert("Post created successfully")}  postFail = {(alert) =>postFail(alert)}/>
   
      </>
    }/>
      <Route path="/manage_posts" element={
        <>

      <ManagePosts success={(alert) =>greenAlert(alert)} postFail={(alert) =>postFail(alert)}/>
      
      </>
    }/>
      <Route path="/change_password" element={
      <>
      <ChangePassword triggerLoad= {() =>loading.current.style.display="flex"} fail = { (alert) =>postFail(alert)}
       stopLoad={() =>loading.current.style.display="none"} wrong = {() =>postFail("Wrong old password")} 
      checkEmail = {() =>greenAlert("Check your Email to verify password change")}/> 
      <div ref={loading} className="regload"><Loading/></div>
       </>
    } />
    <Route path="/blog_post/:author/:blogid" element={<Detailpost postFail={(text) =>postFail(text)} success={(text) =>greenAlert(text)}/>}></Route>
    <Route path="/description" element={
      <Description />} />
      <Route path="/new_password/:user/:token" element={
      <ChangePasswordLink changed={() =>greenAlert("Password changed successfully")} error = {() =>postFail("Something went wrong.")}/>} />
  </Routes>
   <Footer />
</Router>
  );
}

export default App;
