import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { node_user_posts, node_delete_post, node_update_post } from '../URLS';
import { useNavigate } from "react-router-dom";
import Loading from './Loading';

export default function ManagePosts(props) {
    const [posts, showPosts] = useState([]);
    const [edit, showEdit] = useState("");
    const [display,setDisplay] = useState("grid");
    const file = useRef();
    const  updated = useRef(false);
    const name = useRef("");
    const desc = useRef(""); 
    const image = useRef();
    const navigate =useNavigate();
     const importImage = useRef("");
 const loading= useRef();
    useEffect(()=>{
        if(localStorage.getItem("Login")!==null){
            axios.post(node_user_posts,{
                username:localStorage.getItem("user")
            }).then((res)=>{showPosts(res.data); loading.current.style.display="none";})
            
        }else{
         navigate("/");
        }
    },[])



    const deletePost = (id) =>{
            axios.post(node_delete_post,{
                id:id
             }).then((res)=>showPosts(res.data))
         window.location.reload();
    }

    const savePost = (id) =>{
        if(desc.current.length<50){
            props.postFail(`Your post description is too short`);
            return 0;
        }
        if(name.current.length>25){
            props.postFail(`Your post title is too long`);
            return 0;
        }
        if(updated.current){
        
        axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/post_image",{data:file.current}).then((res) =>{
            toDatabase(id,res.data.secure_url);
        });
       
        }else{
            toDatabase(id,"");
        }
            
    }

    const toDatabase = (id,url) =>{
        axios.post(node_update_post,{
                id:id,
                name:name.current,
                desc:desc.current,
                image:url,
                updated:updated.current
            }).then((res)=>{
                res.status ===200 && props.success("Post updated successfully");
               setTimeout(() =>navigate("/manage_posts"),1000) 
            }
        
            )
    }

      const uploadImage = (e) =>{
           const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = function() {
         var base64data = reader.result;               
         file.current=base64data;
            }
        
        const url = URL.createObjectURL(e.target.files[0]);
        image.current.src =  url;
       updated.current=true;
      
    } 
    const initEdit = (item) =>{
       name.current=item.post_name;
       desc.current = item.post_description;
   
        setDisplay("none");


       showEdit(
        <div className="editMenu" >  
            <i className="fa-solid fa-arrow-left" onClick={() =>{setDisplay("grid"); showEdit("")}}></i>
     
        <div className="post">
            <div className="post_photo_div" >
               <img src={item.post_photo} alt="" ref={image} className='post_photo_big' />
            </div>

               
              
                     <div className="tool_legend">
                            <div className="tool_delete" onClick={() =>deletePost(item.id)}> <p>Delete</p> <i className="fas fa-trash-alt deletePost" ></i></div>
                            <div className="edit_picture" onClick = {() =>{importImage.current.click()}}><p>Edit Picture</p><i className="fa-solid fa-images"></i></div>
                             <input type="file" accept="image/png, image/gif, image/jpeg" className='importImage' ref={importImage} onChange={(e) =>uploadImage(e)}/>
                            <div className="save_changes" onClick={() =>savePost(item.id)} ><p>Save changes</p> <i className="fa-solid fa-check"></i></div> 
                     </div>


    <h1 className="post_name" contentEditable="true" suppressContentEditableWarning={true} onInput={(e) =>name.current=e.target.innerHTML}>{name.current} </h1>
    <p className="post_desc" contentEditable="true" suppressContentEditableWarning={true}  onInput={(e) =>{desc.current=e.target.innerHTML}}>{desc.current} </p>
      
        </div>
          
        </div>
        )
    }
   
  return<>   <div ref={loading}><Loading/></div>
  <div className='manage'>
    <h1 className="page_header">YOUR POSTS</h1>
    <div className="manage_posts" style={{display:display}}>
    {posts.map((item)=>{
             return(
        <div className='blog' key={item.id}>
        <img src={item.post_photo} alt="" className='blog_photo'/>
        <h2 className='blog_name'>{item.post_name}</h2>
        <p className="blog_desc">{item.post_description.slice(0,38)+"..."}</p>
        <button className="btn" onClick={() =>initEdit(item)}>Edit</button>
      
          </div>
        )
    })}

   
    </div>
    <>
     {edit}
     </>
</div>

</>
}
