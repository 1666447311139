const node_posts = "https://us-central1-servers-72707.cloudfunctions.net/app/posts";
const node_user_post = "https://us-central1-servers-72707.cloudfunctions.net/app/user_post";
const node_user_posts = "https://us-central1-servers-72707.cloudfunctions.net/app/user_posts";
const node_delete_post = "https://us-central1-servers-72707.cloudfunctions.net/app/delete_post";
const node_update_post = "https://us-central1-servers-72707.cloudfunctions.net/app/update_post";
const node_login = "https://us-central1-servers-72707.cloudfunctions.net/app/login";


    export{
        node_posts,node_user_post, node_login, node_user_posts,
        node_delete_post,node_update_post
    }