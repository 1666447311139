import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className="footer">
        <div className="footer_content">
            <ul className='footerul'>
                <li><Link to="/description">Project Description</Link></li>
                {/* <li><a href="https://mantaskontautas.lt/" target="_blank" rel="noreferrer">Main Page</a></li> */}
            </ul>
                &copy; Mantas Kontautas, 2022
        </div>
    
        </div>
  )
}
