
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import axios from "axios"
export default function Settings(props) {
    let navigate = useNavigate();
   const checkCooldown = (e)=>{
       const date = new Date();
            if(Number(localStorage.getItem("Cooldown"))+600000>date){
                props.cooldown();
                navigate("/");
                }
    }
    const deactivate = () =>{
        axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/deactivate_account",{user:localStorage.getItem("user")}).then((res)=>{
            if(res.status===200) {
                localStorage.removeItem("Login");
                localStorage.removeItem("user");
                props.success("Account deactivated");
                navigate("/");
               setTimeout(() =>window.location.reload(), 3000) 
            }
        })
    }
  return (
      <>
             <h1 className='page_header'>SETTINGS</h1> 
    <div className="settings">
       <div className="settings_box" onClick={(e) => checkCooldown(e) }>
          <Link to="/change_password"> <h2 className='settings_h2'>Change Password</h2></Link>
       </div>
       <div className="settings_box">
           <h2 className='settings_h2' onClick={() =>deactivate()}>Deactivate Account </h2>
       </div>
        </div>
        </>
  )
}
