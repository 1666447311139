import React from 'react'

export default function Introduction() {
  return (
    <div className='intro'>
        <div className="postera">
                  <h1 className='posteraText'>Postato.</h1>
                  <p className='posteraDesc'>Create and upload blogs your way</p>
        </div>
                         <div className="anim">

                           
                              <img src="images/Postato.svg" className='postato' />
                              
                          </div>
    </div>
  )
}
