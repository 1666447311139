import React, { useEffect, useRef } from 'react'

export default function Loading() {
    const cube1 = useRef();
    const cube2 = useRef();
    const cube3 = useRef();
    useEffect(() =>{
         setTimeout(() =>cube2.current.classList.add("cube2_jump"),200)
    setTimeout(() =>cube3.current.classList.add("cube1"),300)

    })
   
  return (
    <div className='loading_overlay'>
        
        <div className="cube1" ref={cube1}></div>
        <div className="cube2" ref={cube2}></div>
        <div className="cube3" ref={cube3}></div>
      
    
        
    </div>
  )
}
