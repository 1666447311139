import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import {node_user_post} from "../URLS";
import {useParams} from "react-router-dom";
import Comments from './Comments';
import Loading from './Loading';

export default function Detailpost(props) {
    let params = useParams();
    const [post,setPost] = useState([]);
    const loading= useRef();
  useEffect(()=>{
      let id = params.blogid.split("_")[1];
      axios.post(node_user_post,{id:id}).then((res)=>{
        setPost(res.data[0]);
        loading.current.style.display="none";
      })
  },[params.blogid,props])

  return <>
   <div ref={loading}><Loading/></div>
  <div className='post'>
    <img src={post.post_photo} alt="" className='post_photo_big'/>
      <div className="author"> <i className="fas fa-user post_avatar"></i><p className="post_author">{post.author}</p></div>
    <h1 className="post_name">{post.post_name}</h1>
    <p className="post_desc">{post.post_description}</p>
    <Comments id={params.blogid.split("_")[1]} postFail = {(text) =>props.postFail(text)} success={(text) =>props.success(text)}/>
  </div>
  </>
}
