import React, { useEffect, useRef, useState } from 'react'
import {node_posts} from "../URLS";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Loading from './Loading';

export default function Blogposts(props) {
    const [posts, setPost] = useState([]);
    const loading = useRef();
    const navigate = useNavigate();
        useEffect(() => {
         
        axios.get(node_posts).then((res) =>{
                setPost(res.data);
                loading.current.style.display="none";
                });    
        }, [props,props.refresh])

 
    


    return (
        <>
        <div ref={loading}><Loading/></div>
        <div className="blogBoxes">
         {posts.map((item)=>{
             return(
        <div className='blog' key={item.id} onClick={() =>{window.scrollTo(0, 0); navigate(`/blog_post/${item.author}/blog_${item.id}`)}}>
        <img src={item.post_photo} alt="" className='blog_photo'/>
        <h2 className='blog_name'>{item.post_name}</h2>
        <p className="blog_desc">{item.post_description.slice(0,38)+"..."}</p>
        </div>
             )  
         })}
             
        </div>
        </>
    )


}