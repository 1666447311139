import React from 'react'

export default function Description() {
  return (
    <div className="description">
        
        <h1 className="page_header">PROJECT DESCRIPTION</h1>
        <p className="desc_text">This project was created to showcase my programming knowledge in building Full Stack applications. Everything you see on this page is created by me, including the design and the logo. 
        Postato is a website that lets you upload, edit and delete blogpages as well as comment on other blogs. There is a login system so that way you will not lose the posts you've created.</p>
        <h3>Tools used</h3>
        <ul>
            <li><a href="https://cloudinary.com/" className="desc_link"><b>Cloudinary</b></a> – cloud-based image and video management services. It enables users to upload, store, manage, manipulate, and deliver images and video for websites and apps.</li>
            <li><b>bcrypt & jsonwebtoken</b> – password encryption and login state validation</li>
            <li><b> React JS </b>  – Frontend</li>
            <li><b> SASS </b>  – Styling</li>
             <li><b> Adobe XD </b>  – Sketching and designing</li>
            <li><b> MYSQL and phpMyAdmin </b>  – Database</li>
            <li><b>Node JS and Express JS </b>  – Backend</li>
            <li><b>Google Firebase </b>  – Hosting the Backend</li>
        </ul>
        <h3>The data is not being used in any way except login. The real unencrypted password never reaches the database</h3>
        </div> 
  )
}
