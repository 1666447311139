import React, { useState } from 'react'
import { Link } from 'react-router-dom';
export default function Navbar(props) {

    const [dropdown,toggleDropdown] = useState("none");
    const logout = () =>{
        localStorage.removeItem("Login");
        window.location.reload();
    }
 const toggleDropdownstate = ()=>{
    if(dropdown==="none"){
        toggleDropdown("block")
    }else{
        toggleDropdown("none")
    }
   }
  
    return (
         <>
        <div className='navbar'>
            <div className="width">
                <div className="nav">
            <Link to="/" onClick={() =>toggleDropdown("none")}><img src={props.image} alt="" className='logo'/></Link>
            <div className="buttons" style={{display:props.loggedIn && "none"}} >
                <p className='login' onClick={props.onLoginClick}>Login</p>
                <p className='register' onClick = {props.onRegClick}>Register</p>
            </div>
            <div className="user" style={{display:!props.loggedIn && "none"}}>
               
                   <i className="fas fa-user avatar" onClick={() =>toggleDropdownstate()}></i>
                  
                
            </div>
            </div>
        </div>
        </div>
         <ul className="dropdown" style={{display:dropdown}}>
                  <Link to="/new_post"> <li onClick={() =>toggleDropdown("none")}><i className="fas fa-plus-circle"></i> New Post</li></Link>
                    <Link to="/settings"><li onClick={() =>toggleDropdown("none")}><i className="fas fa-cog"></i> Settings</li></Link>
                   <Link to="/manage_posts"><li onClick={() =>toggleDropdown("none")}><i className="fas fa-pen"></i> Manage Posts</li></Link> 
                      <li onClick={() =>{logout();toggleDropdown("none")}}><i className="fas fa-sign-out-alt"></i> Logout</li>
                   </ul>
                  </>
    )
}
