import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function ChangePassword(props) {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    let navigate = useNavigate();
     if(localStorage.getItem("Login")===null){
           navigate("/");
        }
        const changePassword = (e) =>{
                  e.preventDefault()
                  props.triggerLoad();
            if(newPassword===repeatPassword){
                 axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/change_password",{user:localStorage.getItem("user"),oldPassword,newPassword})
                 .then(res =>{
                    if(res.status!==200){
                    
                        props.fail("Something went wrong. Try again later");
                        return 0;
                    }
                     if(res.data===false){
                        props.stopLoad();
                        props.wrong();
                     }else{
                        props.stopLoad();
                           props.checkEmail();
                            navigate("/");
                     }
                 })
            }else{
                props.stopLoad();
               props.fail("Something went wrong. Try again later")
            }
           
      
        }
  return (
      
    <div>
      
       <h1 className='page_header'>CHANGE PASSWORD</h1> 
       <form action="" className="change_password">
           <h3>Old Password</h3>
           <input type="password" value={oldPassword} onChange={(e) =>setOldPassword(e.target.value)}/>
           <h3>New Password</h3>
           <input type="password" value={newPassword} onChange={(e) =>setNewPassword(e.target.value)}/>  
           <h3>Repeat New Password</h3>
           <input type="password" value={repeatPassword} onChange={(e) =>setRepeatPassword(e.target.value)}/>  
             <button className="change_password_btn" onClick={(e) =>changePassword(e)}>Change</button> 
       </form>
        
        </div>
  )
}
