import axios from 'axios';
import React from 'react'
import { useNavigate } from "react-router-dom";
export default function ChangePasswordLink(props) {
     let navigate = useNavigate();

        const url = window.location.href;
     
        console.log(url.split("/")[4]);
        axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/change_password/email_auth",{user:url.split("/")[4],token:url.split("/")[5]}).then(res =>{
         const date = new Date();
            if(res.status!==200||Number(localStorage.getItem("Cooldown"))+600000>date){
              props.error();
              navigate("/");
            }else  if(res.status===200){
                  props.changed();
                             localStorage.setItem("Cooldown",date.getTime());
                               navigate("/");
            }
           
           
        }
            )

  
  return (
    <div></div>
  )
}
