import React from 'react'

export default function Alertbox(props) {
    return (
        <div className="alertbackground" style={{display:props.message==="" && "none",border:props.success ? "solid 3px green" : "solid 3px red"}}>
        <div className='alertbox'>
           {props.success &&  <i className="far fa-check-circle circle" ></i>}
           {!props.success && <i className="fas fa-exclamation-circle circle" ></i>}
            <p className='alertp'>{props.message}</p>
        </div>
        </div>
    )
}
