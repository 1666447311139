import axios from 'axios';
import React, {  useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Loading from './Loading';

export default function NewPost(props) {
   

   
    const [imgsrc, setImgsrc] = useState("");
    const loading = useRef();
    const [file, setFile] = useState("");
    const [nameClass, setNameClass] = useState("editable_name");
    const [descClass, setDescClass] = useState("editable_desc");
    const post = useRef("");
    const desc = useRef("");
    const importImage = useRef("");
    let navigate = useNavigate();
    const createPost = () =>{
        loading.current.style.display="block";
        const postName = post.current.innerHTML;
        const postDesc = desc.current.innerHTML;
        
         if(localStorage.getItem("Login")===null){
            loading.current.style.display="none";
            props.postFail(`Please log in before posting`);
            return 0;
        }
        if(postName===""){
            loading.current.style.display="none";
            props.postFail(`Your post must have a name`);
            return 0;
        }
      
        if(postDesc.length<50){
            loading.current.style.display="none";
            props.postFail(`Your post description is too short`);
            return 0;
        }
        if(postName.length>25){
            loading.current.style.display="none";
            props.postFail(`Your post title is too long`);
            return 0;
        }
            if(file===""){
                loading.current.style.display="none";
            props.postFail(`Please add an image`);
            return 0;
        }
         axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/post_image",{data:file},
      ).then((res)=>{
       loading.current.style.display="none";
            if(res.status===200){
                  axios.post("https://us-central1-servers-72707.cloudfunctions.net/app/new_post",{
                post_name:postName,
                post_desc:postDesc,
                url:res.data.secure_url,
                author:localStorage.getItem("user"),
                public_id:res.data.public_id
            }).then(response =>{
                console.log(response);
                if(response.data.success===true) {
                    props.closePost();
                  navigate("/");
                }
            })
            }else{
                    props.postFail(`Something went wrong. Try again later`);
            }
        });
    }
     const uploadImage = (e) =>{
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = function() {
         var base64data = reader.result;                
         setFile(base64data);
            }
        setImgsrc(URL.createObjectURL(e.target.files[0]));
    }

 
    return (
        <>
        <div ref={loading} className="newpostloading"><Loading/></div>
        <div className='newPostbox'>
            <h1 className="page_header">NEW POST</h1>
           
                  <input type="file" accept="image/png, image/gif, image/jpeg" className='importImage' ref={importImage} onChange={(e) =>uploadImage(e)}/>
             <div className='post'>
      {imgsrc==="" ? <div className="blank"> </div> : <img src={imgsrc} alt="" className='post_photo_upload' />}
   <div className="tool_legend">
                            <div className="tool_delete" onClick={() => navigate("/")}> <p>Cancel</p> <i className="fas fa-times deletePost" ></i></div>
                            <div className="edit_picture" onClick = {() =>importImage.current.click()}><p>Edit Picture</p><i className="fa-solid fa-images"></i></div>
                            <div className="save_changes" onClick={() =>createPost()}  ><p>Upload Post</p> <i className="fas fa-upload"></i></div> 
                     </div>
      
      <h1 className={`post_name ${nameClass}`} onInput={() =>setNameClass("")} ref={post} contentEditable="true" suppressContentEditableWarning={true}>Insert Post Title</h1> 
        <p className={`post_desc ${descClass}`} onInput = {() =>setDescClass("")} ref={desc} contentEditable="true" suppressContentEditableWarning={true}>Insert Post description here</p>
     </div>
        </div>
        </>
    )
}
