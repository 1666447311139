import axios from 'axios';
import React, { useState } from 'react'
import { node_login } from '../URLS';

export default function Login(props) {
  const [username,setUsername] = useState("");
  const [password,setPassword] = useState("");
  const login = (e,props) =>{
       props.triggerLoad();
  e.preventDefault();
  //Give warning if fields empty
  if(username===""||password===""){
      props.stopLoad();
    props.fillIn();
    return 0;
  }

  //Check if logging in with email or username
  let emailLogin = false;
   if(username.split("@").length===2){
    emailLogin = true;
   } 

   //Login
   axios.post(node_login,{
     data:{
      username:username,
      password:password,
      emailLogin:emailLogin
     }
   }).then((res)=>{
      props.stopLoad();
    if(res.data.response===true){
      //Show alert
      props.loggedIn()
      //Set logged in token
      localStorage.setItem("Login",res.data.key);
      //Remember username
      localStorage.setItem("user",username);
    }else{
      //Show alert
      props.wrong()
    }
   })



  }
    return (
      <>
    
        <h1 className='loginh1'>Login</h1>
        <form className="logininputs">
        <label htmlFor="username">Username</label>
        <input type="text" name='username' autoComplete='username' value={username} onChange={(e) =>setUsername(e.target.value)}/>
        <label htmlFor="password">Password</label>
        <input type="password" name='current-password' autoComplete='current-password' value={password} onChange={(e) =>setPassword(e.target.value)}/>
            <button className='loginbutton' onClick={(e) =>login(e,props)} >Login</button>
        </form>
     
         <div className="notlogged">Don't have an account?  <div className="underline" onClick={() =>props.showReg()}>Register here</div></div>
        </>
    )
}
